import { Link } from "react-router-dom";
import { Fragment } from "react";
import styles from './NotFound.module.css';
import Card from "../UI/Card";

const NotFound = () => {
  return (
    <Card className={styles.notfound}>
      <h1>404 - Page not found</h1>
      <Link to="/">
        <h2>Return Home</h2>
      </Link>
    </Card>
  );
};

export default NotFound;
