import classes from './StartingPageContent.module.css';
import { useAuth0 } from '@auth0/auth0-react';

const StartingPageContent = () => {
  const { user, isAuthenticated } = useAuth0();
  return (
    <section className={classes.starting}>
      {isAuthenticated && <h1>Hi {user.nickname}!</h1>}
      <h2>Welcome to ZAMM Studios{/*isAuthenticated && ` ${user.nickname}`*/}!</h2>
    </section>
  );
};

export default StartingPageContent;
