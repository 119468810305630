// import CardForm from "../components/Stripe/CardForm";
import { Fragment } from "react";
import CheckoutButton from "../components/Stripe/CheckoutForm";
// import Card from "../components/UI/Card";

const CheckoutPage = () => {
  return (
    <Fragment>
      {/* <h1>Proceed to Checkout</h1> */}
      <CheckoutButton />
    </Fragment>
  );
  //<CardForm />
};

export default CheckoutPage;
