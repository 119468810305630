// src/components/auth-nav.js

import React from "react";
// import AuthenticationButton from "../Auth/AuthenticationButton";
// import SignupLink from "../Auth/AuthenticationHeader";
import AuthenticationHeader from "../Auth/AuthenticationHeader";


const AuthNav = () => (
  <AuthenticationHeader />
);

export default AuthNav;