import styles from "./Checkout.module.css";
import Card from "../UI/Card";

import { useAuth0 } from "@auth0/auth0-react";

const CheckoutForm = (props) => {
  const starterPackage = process.env.REACT_APP_STARTER_PRICE_KEY;
  const ultimatePackage = process.env.REACT_APP_ULTIMATE_PRICE_KEY;

  const { user } = useAuth0();

  let stripe_id = null;
  let plan = null;

  if (user['https://zamm.studio/stripe_id'] != null) stripe_id = user['https://zamm.studio/stripe_id'];

  return (
    <Card className={`${styles.card} ${styles.checkout}`}>
      <form
        action={"https://rycgar.com/zamm/api/create-checkout-session.php"}
        method="POST"
      >
        <h1 className={styles.header}>Select Plan</h1>
        <div className={styles.break} />
        <Card className={styles['package-card']}>
        <fieldset>
          <div className={styles.package}>
            <label htmlFor="starter-package">
              <input
                type="radio"
                id="starter-package"
                name="zammPackageId"
                value={starterPackage}
                defaultChecked
              />
              ZAMM Starter Unlimited Video Editing ($1497/month)
            </label>
          </div>
          <div className={styles.package}>
            <label htmlFor="ultimate-package">
              <input
                type="radio"
                id="ultimate-package"
                name="zammPackageId"
                value={ultimatePackage}
              />
              ZAMM Ultimate Unlimited Video Editing ($1697/month)
            </label>
          </div>
        </fieldset>
        </Card>
        <input type="hidden" name="authId" value={user.sub} />
        <input type="hidden" name="cusId" value={stripe_id} />
        {/* <div className={styles.break} /> */}
        <button type="submit">Checkout</button>
      </form>
    </Card>
  );
};

export default CheckoutForm;
