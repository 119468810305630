// src/components/authentication-button.js

import React, { Fragment } from "react";

import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import SignupLink from "../Layout/SignupLink";

import { useAuth0 } from "@auth0/auth0-react";

const AuthenticationHeader = () => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? (
    <LogoutButton />
  ) : (
    <Fragment>
      <li>
        <SignupLink />
      </li>
      <li>
        <LoginButton />
      </li>
    </Fragment>
  );
};

export default AuthenticationHeader;
