// src/components/auth-nav.js

import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import classes from "./MainNavigation.module.css";

const PrimaryNav = () => {
  const { isAuthenticated, user } = useAuth0();

  let isCustomer = false;
  if (user) {
    if (user["https://zamm.studio/stripe_id"] != null) {
      isCustomer = true;
    }
  }

  return (
    <Fragment>
      {isAuthenticated && (
        <li>
          <NavLink activeClassName={classes.active} to="/profile">
            Profile
          </NavLink>
        </li>
      )}
      {isAuthenticated && (
        <li>
          <NavLink activeClassName={classes.active} to="/projects">
            Projects
          </NavLink>
        </li>
      )}
      {isAuthenticated && !isCustomer && (
        <li>
          <NavLink activeClassName={classes.active} to="/checkout">
            Checkout
          </NavLink>
        </li>
      )}
      {isAuthenticated && isCustomer && (
        <li>
          <NavLink activeClassName={classes.active} to="/subscription">
            Subscription
          </NavLink>
        </li>
      )}
      {!isAuthenticated && (
        <li>
          <a href="https://zamm.studio">What is ZAMM?</a>
        </li>
      )}
    </Fragment>
  );
};

export default PrimaryNav;
