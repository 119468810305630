// src/components/signup-button.js

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const SignupLink = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <a
      href="signup-link"
      onClick={(event) => {
        event.preventDefault();
        loginWithRedirect({
          screen_hint: "signup",
        });
      }}
    >
      Sign Up
    </a>
  );
};

export default SignupLink;
