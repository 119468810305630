import { Switch, Route, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Layout from "./components/Layout/Layout";
import UserProfile from "./components/Profile/UserProfile";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/HomePage";
import Loading from "./components/Display/Loading";
import Profile from "./components/Profile/Profile";
import ProtectedRoute from "./auth/ProtectedRoute";
import CheckoutPage from "./pages/CheckoutPage";
import NotFoundPage from "./pages/NotFoundPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import LoginPage from "./pages/LoginPage";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Elements stripe={stripePromise}>
      <Layout>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/auth">
            <AuthPage />
          </Route>
          <Route path="/login">
            {!isAuthenticated ? <LoginPage /> : <Redirect to="/" />}
          </Route>
          <ProtectedRoute path="/profile" component={Profile} />
          <ProtectedRoute path="/checkout" component={CheckoutPage} />
          <ProtectedRoute path="/subscription" component={SubscriptionPage} />
          {/* <Profile /> */}
          {/* <UserProfile /> */}
          {/* </ProtectedRoute> */}
          <Route component={NotFoundPage} />
        </Switch>
      </Layout>
    </Elements>
  );
}

export default App;
