
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const LoginPage = () => {
    const { loginWithRedirect } = useAuth0();
    useEffect(() => {
        console.log("redirecting to login page");
        loginWithRedirect()
    });

    return <h1 style={{color: "white", textAlign: "center"}}>Redirecting to Log In</h1>;
}

export default LoginPage;