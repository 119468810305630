import classes from "./Subscription.module.css";
import Card from "../UI/Card";

import { useAuth0 } from "@auth0/auth0-react";

const Subscription = () => {
  const { user } = useAuth0();

  let stripe_id = null;
  let plan = null;
  let planText = "You are currently on the free trial with one video remaining";
  let isEmployee = false;

  if (user["https://zamm.studio/stripe_id"] != null)
    stripe_id = user["https://zamm.studio/stripe_id"];

  if (user["https://zamm.studio/plan"] != null)
    plan = user["https://zamm.studio/plan"];

  if (plan === "employee") {
    planText = "You are an employee";
    isEmployee = true;
  } else if (plan === "ultimate") planText = "You are on the ZAMM Ultimate plan";
  else if (plan === "starter") planText = "You are on the ZAMM Starter plan";

  return (
    <Card className={`${classes.card} ${classes.checkout}`}>
      <form
        action={"https://rycgar.com/zamm/api/create-portal-session.php"}
        method="POST"
      >
        <div className={classes.header}>
          {!isEmployee && <h1>Update Subscription</h1>}
          <h2>{planText}</h2>
        </div>
        <div className={classes.break} />

        <input type="hidden" name="authId" value={user.sub} />
        <input type="hidden" name="cusId" value={stripe_id} />
        {/* <div className={classes.break} /> */}
        {!isEmployee && <button type="submit">Manage</button>}
      </form>
    </Card>
  );
};

export default Subscription;
