import React from "react";
import ReactDOM from "react-dom";

// EXPENSE TRACKER
// import './ExpenseTracker/index.css';
// import App from './ExpenseTracker/App';

// ReactDOM.render(<App />, document.getElementById('root'));

// COURSE GOALS
// import './CourseGoals/index.css';
// import App from './CourseGoals/App';

// ReactDOM.render(<App />, document.getElementById('root'));

// DUMMY LOGIN
// import "./DummyLogin/index.css";
// import App from "./DummyLogin/App";
// import { AuthContextProvider } from "./DummyLogin/store/auth-context";

// ReactDOM.render(
//   <AuthContextProvider>
//     <App />
//   </AuthContextProvider>,
//   document.getElementById("root")
// );

// REACT AUTH
import "./ReactAuth/index.css";
import App from "./ReactAuth/App";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from "./ReactAuth/auth/auth0-provider-with-history";

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById("root")
);
