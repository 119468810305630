// src/components/nav-bar.js

import React from "react";
import { Link } from "react-router-dom";

import classes from "./MainNavigation.module.css";

import PrimaryNav from "./PrimaryNav";
import AuthNav from "./AuthNav";

const NavBar = () => {
  return (
    <header className={classes.header}>
      <Link to="/">
        {/* <img
            className={classes["logo-image"]}
            alt="zamm-logo"
            src="http://zamm.studio/wp-content/uploads/2021/07/zamm_ico_light_2.2.svg"
          /> */}
        <div className={classes.logo}>ZAMM Studios</div>
      </Link>
      <nav>
        <ul>
          <PrimaryNav />
          <AuthNav />
        </ul>
      </nav>
    </header>
  );
};

export default NavBar;
