// src/views/profile.js

import React, { useState, useEffect, Fragment } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import classes from "./Profile.module.css";
import Card from "../UI/Card";

const Profile = () => {
  const { user } = useAuth0(); //, getIdTokenClaims
  const { name, picture, email, nickname } = user;
  const [isLoading, setIsLoading] = useState(false);
  // const [nickname, setNickname] = useState();

  let stripe_id = null;
  let plan = "free";

  if (user["https://zamm.studio/plan"] != null)
    plan = user["https://zamm.studio/plan"];
  if (user["https://zamm.studio/stripe_id"] != null)
    stripe_id = user["https://zamm.studio/stripe_id"];

  // console.log(stripe_id);
  // console.log(plan);

  //   const [appMetadata, setAppMetadata] = useState({});

  //   console.log(JSON.stringify(user));

  //   useEffect(() => {
  //     getIdTokenClaims().then(claims => {
  //         setAppMetadata({
  //             plan: claims['https://zamm.studio/plan'],
  //             stripe_id: claims['https://zamm.studio/stripe_id'],
  //         });
  //       })
  //   }, []);

  // const onChangeNicknameHandler = (event) => {
  //   setNickname(event.target.value);
  // };

  const onSaveChangesHandler = (event) => {
    setIsLoading(true);
    setTimeout(() => {
      window.location.reload(false);
    }, 800);
    // const url = event.target.action;

    // var request = new XMLHttpRequest();
    // request.open("POST", url, true);
    // request.onload = function () {
    //   // request successful
    //   // we can use server response to our request now
    //   console.log(request.responseText);
    // };

    // request.onerror = function () {
    //   // request failed
    // };

    // request.send(new FormData(event.target)); // create FormData from form that triggered event
    // event.preventDefault();

    // event.preventDefault();
    // setIsLoading(true);
    // const nickname = event.target.nickname.value;
    // const authId = user.sub;
    // const url = event.target.action;
    // const method = event.target.method;
    // console.log(nickname + authId);
    // console.log(url);

    // // POST request using fetch with error handling
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ authId: authId, nickname: nickname }),
    //   mode: "no-cors",
    // };
    // fetch(url, requestOptions)
    //   .then(async (response) => {
    //     console.log(response);
    //     const isJson = response.headers
    //       .get("content-type")
    //       ?.includes("application/json");
    //     const data = isJson && (await response.json());

    //     // check for error response
    //     if (!response.ok) {
    //       // get error message from body or default to response status
    //       const error = (data && data.message) || response.status;
    //       return Promise.reject(error);
    //     }

    //     // this.setState({ postId: data.id });
    //   })
    //   .catch((error) => {
    //     // this.setState({ errorMessage: error.toString() });
    //     console.error("There was an error!", error);
    //   });
  };

  return (
    <Card className={`${classes.card} ${classes.profile}`}>
      <div>
        <div>
          <img
            src={picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </div>
        <div>
          <h2>{nickname}</h2>
          <p>Email: {email}</p>
          <p>Plan: {plan}</p>
        </div>
      </div>
      {/* <div className="row">
        <pre className={`col-12 text-light bg-dark p-4 ${classes.profile}`}>
          {JSON.stringify(user, null, 2)}
        </pre> */}
      {/* {appMetadata ? (
          <pre>{JSON.stringify(appMetadata, null, 2)}</pre>
        ) : (
          "No user metadata defined"
        )} */}
      {/* </div> */}
      <iframe
        name="frame"
        id="frame"
        title="frame"
        style={{ visibility: "hidden", display: "none" }}
      />
      <form
        action={"https://rycgar.com/zamm/api/update-nickname.php"}
        method="POST"
        onSubmit={onSaveChangesHandler}
        target="frame"
      >
        <label htmlFor="nickname">
          Name
          <input
            type="text"
            id="nickname"
            name="nickname"
            placeholder="Enter new name..."
            // onChange={onChangeNicknameHandler}
          />
        </label>
        <input type="hidden" name="authId" value={user.sub} />
        {!isLoading ? (
          <button type="submit">Save Changes</button>
        ) : (
          <span>Saving Changes</span>
        )}
      </form>
    </Card>
  );
};

export default Profile;
